import sdk, { iterDump } from '@petplate/sdk';
import { cache } from '@petplate/utils/cache';
import { GraphqlExecutionError } from '../ui/types/errors';

export const COUPON_SETTINGS_KEYS = {
  SITE_WIDE_COUPON: 'site_wide_coupon',
  SITE_WIDEBACKUP_CODE: 'site_wide_backup_code',
  SUB_CANCELLED_ENTREES_DISCOUNT: 'sub_cancelled_entrees_discount'
};

export const buildCouponUpdateErrorMessage = (err: GraphqlExecutionError) => {
  // The non stackable error is a custom one that we throw in the backend when the coupons are not stackable.
  const couponsNotStackableError = err.response.errors.find(
    (e) => e?.extensions?.code === 'NON_STACKABLE_COUPONS'
  );

  const usageLimitReachedError = err.response.errors.find((e) =>
    e.message?.match(/CUSTOMER_USAGE_LIMIT_REACHED/)
  );

  const defaultError =
    'Coupon(s) could not be applied to your subscription. Contact the support team.';

  if (couponsNotStackableError) {
    // This error message contains the actual coupons that couldn't be stacked, so use the backend message in this case
    // instead of trying to see which coupons were not stackable manually.
    return err.response.errors[0]?.message || defaultError;
  }

  if (usageLimitReachedError) {
    return "You've already used this coupon";
  }

  return defaultError;
};

export const loadCoupons = (limit: number) =>
  cache(
    Object.assign(
      async (a: AbortSignal, title: string) => {
        const coupons = await iterDump(
          a,
          async (cursor) => (await sdk.AdminSearchCoupons({ title, cursor })).shopifyCoupons,
          limit
        );

        return Object.fromEntries(coupons.map(({ code, title }) => [code, title ?? code]));
      },
      { many: true }
    )
  );
